import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Code,
	ExampleProps,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Paragraph } from "../../text/paragraph/paragraph";
import { TextContainer } from "../../text/text-container/text-container";
import { Ul } from "../../text/list/list";
import { StructureExampleToggle } from "../common";
import { Content } from "./content";

export const Meta: DocPageMeta = {
	title: "Content.Layout",
	category: "Structure",
	notepad: "https://hackmd.io/C-xa_YmuRkaJGZambO1orQ",
};

/** @ignore */
const StructureExample = (props: ExampleProps) => (
	<Example {...props} customBar={({ id }) => <StructureExampleToggle id={id} />} />
);

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Content.Layout"
			subTitle="Wrap this invisible component around content components. Use it to control their padding, alignment, and positioning. It also makes content components behave responsively."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Content} />
				<InlineMessage variant="warning">
					<Paragraph>
						Our structure components are strongly interconnected. Make sure to read the structure
						component <GatsbyLink to="/lib/components/Structure/🧭 Guide">guide</GatsbyLink>, to
						find out which components you need and how to combine them correctly.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The Content.Layout component is meant to be wrapped around{" "}
					<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components. It
					ensures that the space between itself and the content components is the same as the space
					between the individual content components. Furthermore, you can use the Content.Layout
					component to adjust the padding, alignment, and positioning of the content components.
					Lastly, the Content.Layout component adds responsive behavior to the content components.
					You can find examples of all these features below.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					Here you see a Content.Layout component with default property settings wrapped around two{" "}
					<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components. If you
					resize the browser window, you'll see how the Content.Layout component makes the content
					components behave responsively. Specifically, when the browser window width is{" "}
					<Code>$layout--large</Code> (62rem/992px) or smaller, the content components stack on top
					of each other.
				</Paragraph>
				<StructureExample fn={defaultExample} />
				<Header.H3>Padding</Header.H3>
				<Paragraph>
					Use the Content.Layout component's <Code>padding</Code> property to adjust the padding of
					both the <GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink>{" "}
					components and the Content.Layout component itself. You can use a content component’s{" "}
					<Code>padding</Code> property to override the padding set by the Content.Layout component.
				</Paragraph>
				<Header.H4>Large</Header.H4>
				<StructureExample fn={paddingLargeExample} />
				<Header.H4>Medium (default)</Header.H4>
				<StructureExample fn={paddingMediumExample} />
				<Header.H4>Small</Header.H4>
				<StructureExample fn={paddingSmallExample} />
				<Header.H4>None</Header.H4>
				<StructureExample fn={paddingNoneExample} />
				<Header.H3>Centered</Header.H3>
				<Paragraph>
					Use the <Code>centered</Code> property to position the Content.Layout component in the
					horizontal center of the page.
				</Paragraph>
				<Header.H4>Small</Header.H4>
				<Paragraph>
					A property value of <Code>small</Code> limits the width of the Content.Layout component to
					a maximum of 37.5rem (600px).
				</Paragraph>
				<StructureExample fn={centeredSmallExample} />
				<Header.H4>Medium</Header.H4>
				<Paragraph>
					A property value of <Code>medium</Code> limits the width of the Content.Layout component
					to a maximum of 56.25rem (900px).
				</Paragraph>
				<StructureExample fn={centeredMediumExample} />
				<Header.H3>Flex container</Header.H3>
				<Paragraph>
					The Content.Layout component offers the same Content.Layout options as the parent element,
					or flex container, of a{" "}
					<a href="https://css-tricks.com/snippets/css/a-guide-to-flexbox/">CSS flexbox</a>. That
					means you can use its <Code>flexDirection</Code>, <Code>flexWrap</Code>,{" "}
					<Code>justifyContent</Code>, <Code>alignItems</Code>, <Code>alignContent</Code> and{" "}
					<Code>gap</Code> properties to control the positioning and alignment of the{" "}
					<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components. The
					two examples below showcase how these properties enable you to achieve different
					Content.Layouts.
				</Paragraph>
				<Header.H4>Example A</Header.H4>
				<StructureExample fn={flexContainerExampleA} />
				<Header.H4>Example B</Header.H4>
				<StructureExample fn={flexContainerExampleB} />
				<Header.H4>Example C</Header.H4>
				<Paragraph>
					This example uses the <Code>gap</Code> property to get equal spacing between the children
					elements. The default value of <Code>gap</Code> is <Code>medium</Code>, but it can also be{" "}
					<Code>xxSmall</Code>, <Code>xSmall</Code>, <Code>small</Code> and <Code>large</Code>.
				</Paragraph>
				<StructureExample fn={flexContainerExampleC} />
				<Header.H2>Properties</Header.H2>
				<InlineMessage variant="warning">
					<Paragraph>
						Some property values are not supported by IE11. These are noted in the table below. When
						you use one of these values, check how bad it looks in IE11. It's up to a designer to
						decide whether it's acceptable or not. If not, tweak the design or check if a different
						(IE11 supported) property value achieves a satisfactory result.
					</Paragraph>
				</InlineMessage>
				<Knobs
					component={Content.Layout}
					initialProps={{
						children: (
							<>
								<Content>
									<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Paragraph>
								</Content>
								<Content>
									<Paragraph>
										Vestibulum eu sodales nibh, tempor imperdiet dui. Integer eu condimentum odio.
										Mauris aliquet euismod tortor, sit amet scelerisque magna pellentesque in.
									</Paragraph>
								</Content>
								<Content>
									<Paragraph>
										Integer eu iaculis odio, quis cursus dui. Mauris consectetur, ante at rhoncus
										accumsan, sem turpis aliquet lorem, a cursus nibh neque vel magna. Fusce
										ultrices mattis sem, et volutpat purus fringilla non. Phasellus eleifend odio
										ipsum, id hendrerit nunc lacinia blandit. Nam iaculis sem at lectus luctus, nec
										dictum mi tempus.
									</Paragraph>
								</Content>
								<Content>
									<Paragraph>
										Nunc risus lorem, commodo vitae ante vitae, volutpat consequat erat. Duis
										convallis vulputate odio eu sodales.
									</Paragraph>
								</Content>
							</>
						),
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								In most cases, it’s a good idea to wrap adjacent{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components
								in a Content.Layout component. This will ensure equal spacing between the content
								components and around the content omponents as a group.
							</>,
							<>
								Only wrap Content.Layout components around{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components.
								Use it to control their padding, alignment, and positioning, and to add responsive
								behavior.
							</>,
							<>
								Content.Layout components can be used in various UI contexts, such as{" "}
								<GatsbyLink to="#">(dead link) cards</GatsbyLink>,{" "}
								<GatsbyLink to="#">(dead link) modals</GatsbyLink>, or
								<GatsbyLink to="#">(dead link) sidepanels</GatsbyLink>.
							</>,
							<>
								Use the Content.Layout component's <Code>padding</Code> property to adjust the
								padding of both the{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components
								and the Content.Layout component itself.
							</>,
							<>
								Use the <Code>centered</Code> property to horizontally center the wrapped{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components{" "}
								and limit their width to a maximum of either 600 or 900 pixels.
							</>,
							<>
								Use the <Code>flexDirection</Code>, <Code>flexWrap</Code>,{" "}
								<Code>justifyContent</Code>, <Code>alignItems</Code> and <Code>alignContent</Code>{" "}
								properties to control the positioning and alignment of the wrapped{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components.
							</>,
							<>
								You can’t use a Content.Layout component to control the size of an individual{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> component
								wrapped inside it. You need to use the content component’s <Code>flex</Code>{" "}
								property instead.
							</>,
							<>
								You can’t use a Content.Layout component to control the cross-axis alignment of an
								individual <GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink>{" "}
								components wrapped inside it. You need to use the content component's{" "}
								<Code>alignSelf</Code> property instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						You need to control the padding, alignment, or positioning of anything other than a{" "}
						<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> component.
						Instead, either use the properties of the component in question or add custom CSS.
					</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>There are no writing guidelines for this component.</Paragraph>
				</InlineMessage>
				<Header.H2>Notable Changes</Header.H2>
				<Header.H3>Version 0.0.x</Header.H3>
				<Paragraph>
					The <Code>padding</Code> property's default value was renamed from "regular" to "medium".
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => (
	<Content.Layout>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
	</Content.Layout>
);

const paddingLargeExample = () => (
	<Content.Layout padding="large">
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
	</Content.Layout>
);

const paddingMediumExample = () => (
	<Content.Layout padding="medium">
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
	</Content.Layout>
);

const paddingSmallExample = () => (
	<Content.Layout padding="small">
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
	</Content.Layout>
);

const paddingNoneExample = () => (
	<Content.Layout padding="none">
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
	</Content.Layout>
);

const centeredSmallExample = () => (
	<Content.Layout centered="small">
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
	</Content.Layout>
);

const centeredMediumExample = () => (
	<Content.Layout centered="medium">
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
	</Content.Layout>
);

const flexContainerExampleA = () => (
	<Content.Layout flexDirection="column" alignItems="flex-end">
		<Content>
			<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Vestibulum eu sodales nibh, tempor imperdiet dui. Integer eu condimentum odio. Mauris
				aliquet euismod tortor, sit amet scelerisque magna pellentesque in.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Integer eu iaculis odio, quis cursus dui. Mauris consectetur, ante at rhoncus accumsan, sem
				turpis aliquet lorem, a cursus nibh neque vel magna. Fusce ultrices mattis sem, et volutpat
				purus fringilla non. Phasellus eleifend odio ipsum, id hendrerit nunc lacinia blandit. Nam
				iaculis sem at lectus luctus, nec dictum mi tempus.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Nunc risus lorem, commodo vitae ante vitae, volutpat consequat erat. Duis convallis
				vulputate odio eu sodales.
			</Paragraph>
		</Content>
	</Content.Layout>
);

const flexContainerExampleB = () => (
	<Content.Layout flexDirection="row" alignItems="center">
		<Content>
			<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Vestibulum eu sodales nibh, tempor imperdiet dui. Integer eu condimentum odio. Mauris
				aliquet euismod tortor, sit amet scelerisque magna pellentesque in.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Integer eu iaculis odio, quis cursus dui. Mauris consectetur, ante at rhoncus accumsan, sem
				turpis aliquet lorem, a cursus nibh neque vel magna. Fusce ultrices mattis sem, et volutpat
				purus fringilla non. Phasellus eleifend odio ipsum, id hendrerit nunc lacinia blandit. Nam
				iaculis sem at lectus luctus, nec dictum mi tempus.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Nunc risus lorem, commodo vitae ante vitae, volutpat consequat erat. Duis convallis
				vulputate odio eu sodales.
			</Paragraph>
		</Content>
	</Content.Layout>
);

const flexContainerExampleC = () => (
	<Content.Layout padding="none" gap>
		<Content>
			<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Vestibulum eu sodales nibh, tempor imperdiet dui. Integer eu condimentum odio. Mauris
				aliquet euismod tortor, sit amet scelerisque magna pellentesque in.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Integer eu iaculis odio, quis cursus dui. Mauris consectetur, ante at rhoncus accumsan, sem
				turpis aliquet lorem, a cursus nibh neque vel magna. Fusce ultrices mattis sem, et volutpat
				purus fringilla non. Phasellus eleifend odio ipsum, id hendrerit nunc lacinia blandit. Nam
				iaculis sem at lectus luctus, nec dictum mi tempus.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Nunc risus lorem, commodo vitae ante vitae, volutpat consequat erat. Duis convallis
				vulputate odio eu sodales.
			</Paragraph>
		</Content>
	</Content.Layout>
);
